<template>
  <b-sidebar
    id="add-new-category-sidebar"
    :visible="isAddNewCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 pt-1"
      >
        <h5 class="mt-1 mb-0">Email Template</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group class="required" label="Title">
                <b-form-input
                  type="text"
                  placeholder="Title"
                  v-model="emailTemplateData.title"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Subject -->
            <validation-provider
              #default="validationContext"
              name="subject"
              rules="required"
            >
              <b-form-group class="required" label="Subject">
                <b-form-input
                  type="text"
                  placeholder="Subject"
                  v-model="emailTemplateData.subject"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Content -->
            <validation-provider
              #default="validationContext"
              name="content"
              rules="required"
            >
              <b-form-group label="Content">
                <div class="quill-editor">
                  <!-- 图片上传组件辅助-->
                  <el-upload
                    class="avatar-uploader"
                    :action="uploadUrl"
                    :headers="headerObj"
                    name="file"
                    :show-file-list="false"
                    :on-success="uploadSuccess"
                  >
                  </el-upload>
                  <!--富文本编辑器组件-->
                  <quill-editor
                    v-model="emailTemplateData.content"
                    :content="emailTemplateData.content"
                    :options="editorOption"
                    ref="QuillEditor"
                  >
                  </quill-editor>
                </div>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- {{ formData }} -->

            <b-form-group
              label="Status"
              label-for="is_active"
              label-cols-md="3"
              class="mt-2"
            >
              <!-- <b-form-input id="is_active"  v-model="emailTemplateData.is_active" /> -->
              <b-form-checkbox
                :checked="emailTemplateData.is_active"
                name="is_active"
                switch
                inline
                v-model="emailTemplateData.is_active"
                value="1"
                style="margin-top: 5px"
              >
                {{ emailTemplateData.is_active ? "Active" : "InActive" }}
              </b-form-checkbox>
            </b-form-group>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import emailTemplateStoreModule from "../emailTemplateStoreModule";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
// import { statusOptions } from '@/libs/helper'

export default {
  data() {
    return {
      statusOptions,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BOverlay,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BDropdown,
    BDropdownItem,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
  },
  methods: {
    uploadSuccess(res) {
      // 获取富文本组件实例
      console.log({ res });
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "image", res.file_path);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        this.$message.error("图片插入失败！");
      }
    },
    onSubmit() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData();
          //formData.append('file', this.emailTemplateData.file)
          // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
          formData.append("id", this.emailTemplateData.id);
          formData.append("title", this.emailTemplateData.title);
          formData.append("subject", this.emailTemplateData.subject);
          formData.append("content", this.emailTemplateData.content);
          formData.append(
            "is_active",
            this.emailTemplateData.is_active ? 1 : 0
          );
          store
            .dispatch("app-email/storeEmailTemplate", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$emit("update:is-add-new-category-sidebar-active", false);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },

  model: {
    prop: "isAddNewCategorySidebarActive",
    event: "update:is-add-new-category-sidebar-active",
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const APP_FAQ_STORE_MODULE_NAME = "app-email";

    if (!store.hasModule(APP_FAQ_STORE_MODULE_NAME))
      store.registerModule(APP_FAQ_STORE_MODULE_NAME, emailTemplateStoreModule);
    // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    const blankData = ref({
      id: 0,
      is_active: 1,
      click_bit: 1,
      name: "",
      description: "",
      content: "",
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
      slug: "",
      sort_order: "",
      parent_id: "0",
    });

    const emailTemplateData = ref(JSON.parse(JSON.stringify(blankData)));

    const resetemailTemplateData = () => {
      emailTemplateData.value = JSON.parse(JSON.stringify(blankData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetemailTemplateData
    );

    watch(
      () => [props.id, props.isAddNewCategorySidebarActive],
      () => {
        if (props.isAddNewCategorySidebarActive) {
          emailTemplateData.value = JSON.parse(JSON.stringify(props.data));
        }
      }
    );

    const uploadUrl = process.env.VUE_APP_IMAGE_URL;

    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const editorOption = {
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [
              {
                font: [
                  "SimSun",
                  "SimHei",
                  "Microsoft-YaHei",
                  "KaiTi",
                  "FangSong",
                  "Arial",
                ],
              },
            ],
            [{ align: [] }],
            ["clean"],
            ["link", "image"],
          ],
          handlers: {
            image: (value) => {
              console.log("image", value);
              if (value) {
                document.querySelector(".avatar-uploader input").click();
              } else {
                this.quill.format("image", false);
              }
            },
          },
        },
      },
    };

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      emailTemplateData,
      editorOption,
      headerObj,
      uploadUrl,
    };
  },
};
</script>

<style lang="scss">
#add-new-category-sidebar.b-sidebar.b-sidebar-right {
  width: 50% !important;

  .ql-editor {
    min-height: 350px;
  }
}

@media screen and (max-width: 780px) {
  #add-new-category-sidebar.b-sidebar.b-sidebar-right {
    width: 100% !important;
  }
}
</style>
